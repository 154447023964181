import { useEffect, useRef, useState, useCallback } from "react";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import {
  ClickAwayListener,
  Popper,
  Grow,
  Paper,
  MenuList,
  Typography,
  Zoom,
} from "@mui/material";
import { hashHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import CreateReport from "containers/Home/CreateReport/CreateReport";
import { NewReport } from "../NewReport/NewReport";
import { updateReportConfig } from "redux/ReportsConfig/actions";
import styles from "./styles";
import { updateAppBar } from "redux/AppBar/actions";
import { activateItemMenu, isAvailableForPlan } from "utils/utils";
import { updateAppState } from "redux/App/actions";
import moment from "moment";
import {
  PLAN_MANAGEMENT,
  TENANT_STATUS,
  TRIALS,
  TRIAL_RESTART_PERIOD,
  UPGRADE,
} from "utils/app/appConstants";
import LightTooltip, {
  LightTooltipContent,
} from "components/LightTooltip/LightTooltip";
import { localization } from "localization/es";
import PremiumIcon from "components/Icons/PremiumIcon";
import { NewTag } from "components/NewTag/NewTag";
import { SubMenuItem } from "./SubMenuItem";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: "-0.7rem",
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
    background: "#F22C36",
  },
}));

const { ACTIVATE_TRIAL, UPGRADE_PLAN } = PLAN_MANAGEMENT;
const { APP } = localization;

const AppMenuItem = ({ itemKey, menu, sourceError, ...rest }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [open, setOpen] = useState(false);
  const appBar = useSelector((state) => state.get("appBar"));
  const app = useSelector((state) => state.get("app"));
  const [currentPlan, setPlan] = useState("free");
  const userIdentity = useSelector((state) => state.get("userIdentity"));
  const { blockedRoute } = app;
  const anchorRef = useRef(null);
  const dispatch = useDispatch();
  const { accountData } = userIdentity;
  const { trial, plan, tenant_status } = accountData?.properties || {};
  const { type, start_date, duration } =
    (trial && Array.isArray(trial) ? trial[0] : trial) || {};

  const handleToggle = useCallback(() => {
    setOpen((prevOpen) => !prevOpen);
  }, []);

  const handleClose = useCallback((event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  }, []);

  const handleItemMenuClick = useCallback(
    (event) => {
      if (isAvailableForPlan(currentPlan, menu.plan)) {
        if (menu.subItems) {
          handleToggle();
        } else {
          goTo(menu.rootPath);
        }
      } else {
        if (tenant_status === TENANT_STATUS.ONBOARDING) return;
        let trialAvailable = currentPlan === "free" && !trial;
        if (!trialAvailable && currentPlan === "free") {
          let now = moment(new Date());
          let trialStart = moment(start_date, "YYYY-MM-DD");
          let days = now.diff(trialStart, "days");
          if (days > TRIAL_RESTART_PERIOD) trialAvailable = true;
        }
        const { title, subtitle, features, cta } = trialAvailable
          ? TRIALS.PRO
          : UPGRADE.PRO;
        const planManagement = {
          action: trialAvailable ? ACTIVATE_TRIAL : UPGRADE_PLAN,
          open: true,
          title,
          subtitle,
          features,
          cta,
        };
        dispatch(updateAppState({ planManagement }));
      }
    },
    [
      currentPlan,
      menu,
      tenant_status,
      trial,
      start_date,
      dispatch,
      handleToggle,
    ]
  );

  useEffect(() => {
    if (trial) {
      let now = moment(new Date());
      let trialStart = moment(start_date, "YYYY-MM-DD");
      let days = now.diff(trialStart, "days");
      const remainingDays = duration - days;
      if (remainingDays > 0) setPlan(type.toLowerCase());
      else setPlan(plan?.toLowerCase() || "free");
    } else {
      setPlan(plan?.toLowerCase() || "free");
    }
  }, [trial, plan, start_date, duration, type]);

  const handleOnSubItemClick = useCallback(
    (event, id, route, plan) => {
      handleClose(event);
      if (isAvailableForPlan(currentPlan, plan)) {
        goTo(route, id);
      } else {
        if (tenant_status === TENANT_STATUS.ONBOARDING) return;
        let trialAvailable = currentPlan === "free" && !trial;
        if (!trialAvailable && currentPlan === "free") {
          let now = moment(new Date());
          let trialStart = moment(start_date, "YYYY-MM-DD");
          let days = now.diff(trialStart, "days");
          if (days > TRIAL_RESTART_PERIOD) trialAvailable = true;
        }
        const { title, subtitle, features, cta } = trialAvailable
          ? TRIALS.PRO
          : UPGRADE.PRO;
        const planManagement = {
          action: trialAvailable ? ACTIVATE_TRIAL : UPGRADE_PLAN,
          open: true,
          title,
          subtitle,
          features,
          cta,
        };
        dispatch(updateAppState({ planManagement }));
      }
    },
    [currentPlan, tenant_status, trial, start_date, dispatch, handleClose]
  );

  const getItemTitle = useCallback(
    (id) => {
      const activeSubItem = menu.subItems.find((item) => item.id === id);
      return activeSubItem.name;
    },
    [menu.subItems]
  );

  const goTo = useCallback(
    (path, subItemId) => {
      if (rest.location.pathname === blockedRoute) {
        dispatch(updateAppState({ showRouteBlockedAlert: true, goNext: path }));
        return;
      }
      let title = subItemId ? getItemTitle(subItemId) : menu.name;
      const host = window.location.hostname.split(".");
      window.document.title = `${title} | ${host[1]}`;
      dispatch(
        updateReportConfig({
          filters: [],
          quickFilters: {},
          lastFilterSelected: [],
          dashboardMetric: "sales",
          loadingConfig: true,
          offset: 0,
          isSearch: false,
          searchCriteria: undefined,
          reportType: undefined,
          filtersLabels: {},
          filtersKeys: {},
        })
      );
      dispatch(updateAppBar(activateItemMenu(appBar, itemKey, subItemId)));
      hashHistory.push(path);
    },
    [
      blockedRoute,
      dispatch,
      getItemTitle,
      menu.name,
      rest.location.pathname,
      appBar,
      itemKey,
    ]
  );

  const color = menu.active ? "#3770EF" : "#78909C";

  return (
    <>
      <StyledBadge
        key={itemKey}
        badgeContent={sourceError}
        invisible={itemKey !== "sources"}
        data-intercom-target={`menu-${itemKey}`}
        id={`menu-${itemKey}`}
      >
        <LightTooltip
          TransitionComponent={Zoom}
          title={
            isAvailableForPlan(currentPlan, menu.plan) ? (
              ""
            ) : (
              <LightTooltipContent
                title={APP.UPGRADE_TITLE}
                description={APP.UPGRADE_DESCRIPTION}
              />
            )
          }
          placement="bottom-start"
        >
          <Typography
            className="body2"
            sx={{
              ...styles.menu,
              "&:hover": {
                color: "#3770EF",
              },
              color,
            }}
            data-intercom-target={`menu-${itemKey}`}
            onClick={handleItemMenuClick}
            ref={anchorRef}
            id={`menu-${itemKey}`}
            aria-controls={open ? `composition-menu-${itemKey}` : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            data-cy={menu["data-cy"]}
          >
            {menu.plan !== "free" && (
              <span
                style={{
                  marginRight: "0.5em",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <PremiumIcon />
                </div>
              </span>
            )}
            {menu.name}
            {menu.subItems && <ArrowDropDown />}
            {menu?.tag && (
              <div style={{ marginLeft: "0.5em" }}>
                <NewTag tag={menu?.tag} />
              </div>
            )}
          </Typography>
        </LightTooltip>
      </StyledBadge>
      <Popper
        sx={{ zIndex: 1 }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-start" ? "left top" : "left bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <div style={{ marginTop: "1em", paddingTop: "5px" }}>
                  {itemKey === "reports" && (
                    <NewReport
                      handleOnClick={(e) => {
                        handleClose(e);
                        setOpenDialog(true);
                      }}
                    />
                  )}
                  <MenuList
                    autoFocusItem={open}
                    id={`composition-menu-${itemKey}`}
                    aria-labelledby={`composition-button-${itemKey}`}
                    sx={styles.menuList}
                  >
                    {menu?.subItems &&
                      menu.subItems.map((item, index) => (
                        <SubMenuItem
                          key={item.id}
                          item={item}
                          index={index}
                          menu={menu}
                          handleOnSubItemClick={handleOnSubItemClick}
                        />
                      ))}
                  </MenuList>
                </div>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      <CreateReport
        open={openDialog}
        handleClose={() => setOpenDialog(false)}
      />
    </>
  );
};

export default AppMenuItem;
