import { useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { Box, FormControl, TextField } from "@mui/material";

import WivoButton from "components/WivoButton/Button";
import { registerUserDataAut0 } from "services/alpaca/request";
import {
  registerEvent,
  registerIdentify,
  registerSentryError,
} from "utils/utils";
import { updateUserIdentity } from "redux/UserIdentity/actions";
import { referUsers } from "services/almiqui/referred/request";
import { enqueueSnackbar } from "notistack";
import { SEGMENT_EVENT } from "utils/app/appConstants";

export function BenchmarkContent({ onInvite }) {
  const identity = useSelector((state) => state.get("userIdentity"));
  const [invitedEmail, setEmail] = useState("");
  const dispatch = useDispatch();

  const findMilestone = (milestone) => {
    return milestones.find((mil) => mil === milestone);
  };

  const {
    accountData: { client },
    userData: { user_id, user_metadata, name, email },
    accountData: { tenant },
  } = identity;

  const milestones = user_metadata?.milestones ?? [];

  const benchmarkInviteUser = () => {
    onInvite();
    const users = {
      tenant,
      userId: user_id,
      invitedBy: name,
      invitedByEmail: email,
      users: [invitedEmail],
      tenant_name: client,
      invited_from_benchmark: true,
    };
    referUsers(users)
      .then((res) => {
        const { status, err, data } = res;
        if (err) throw new Error();
        if (status === 200) {
          const { response_status_codes } = data;
          for (const invitationStatus of response_status_codes) {
            const type = invitationStatus === 201 ? "success" : "error";
            const title =
              invitationStatus === 201 ? "Invitación enviada" : "Error";
            const text =
              invitationStatus === 201
                ? `La invitacion a ${invitedEmail} ha sido enviada satisfactoriamente.`
                : invitationStatus === 409
                ? `El usuario del correo ${invitedEmail} ya existe en Wivo.`
                : `Ha fallado la invitación para el correo ${invitedEmail}.`;
            const toastData = { type, title, text };
            enqueueSnackbar("", {
              variant: "snackbar",
              ...toastData,
            });

            if (process.env.REACT_APP_DEV_MODE === "false") {
              if (!findMilestone(milestones, "benchmark_invited_user")) {
                const user_metadata = {
                  milestones: [...milestones, "benchmark_invited_user"],
                };
                registerUserDataAut0({
                  user_id,
                  user_data: { user_metadata },
                }).then((resp) => {
                  registerIdentify(user_id, {
                    invited_user: true,
                  });
                  dispatch(
                    updateUserIdentity({
                      userData: {
                        ...identity.userData,
                        user_metadata: {
                          ...identity.userData.user_metadata,
                          milestones: [...milestones, "benchmark_invited_user"],
                        },
                      },
                    })
                  );
                });
              }
              registerEvent(
                SEGMENT_EVENT.INVITE_USER,
                {
                  invite_email: invitedEmail,
                  invited_from_benchmark: true,
                },
                {
                  context: {
                    groupId: tenant,
                  },
                }
              );
            }
          }
        }
      })
      .catch((err) => {
        const toastData = {
          type: "error",
          title: "Error",
          text: "La invitación ha fallado. Asegúrese de que el usuario no haya sido invitado ya, o comuníquese con el equipo de soporte.",
        };
        enqueueSnackbar("", {
          variant: "snackbar",
          ...toastData,
        });
        registerSentryError(err?.message || err);
      });
  };
  return (
    <>
      <FormControl>
        <p
          id="benchmark-invite-company"
          style={{
            fontSize: "1rem",
            lineHeight: "1.5",
            textWrapStyle: "pretty",
            display: "inline-block",
          }}
        >
          Mientras más empresas se unan a Wivo, más precisa y útil será la
          información comparativa. ¿Conoces a otra empresa que pueda
          beneficiarse de Wivo?
          <br />
          <span style={{ marginTop: "4px", fontWeight: "bold" }}>
            ¡Invítala ahora y contribuye a enriquecer el Dashboard Benchmarks
            para toda la comunidad!
          </span>
        </p>
        <Box
          display="flex"
          columnGap={1}
          justifyContent={"center"}
          alignItems={"center"}
          mt={2}
        >
          <TextField
            value={invitedEmail}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="email"
            size="small"
            inputProps={{ style: { color: "var(--blue-gray-5)" } }}
            label="Invita a otra empresa"
          />
          <WivoButton label={"Invitar"} handleOnClick={benchmarkInviteUser} />
        </Box>
        <p
          id="footer-benchmark-invite-company"
          style={{
            marginTop: "32px",
            fontSize: "0.8rem",
            lineHeight: "1.5",
            textWrapStyle: "pretty",
            display: "inline-block",
            color: "#919699",
          }}
        >
          ¿Por qué? Al contar con más vendedores en Wivo, los datos de
          referencia (benchmarks) se vuelven mucho más completos y podremos
          incluir más funcionalidades como comparaciones por categoría,
          ayudándote a tomar mejores decisiones.
          <br />
          <span>
            <strong>Importante:</strong> la persona invitada no tendrá acceso a
            tu cuenta en Wivo
          </span>
        </p>
      </FormControl>
    </>
  );
}
