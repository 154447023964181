const styles = {
  appBar: { background: "#fff",boxShadow:"none"},
  logoSection: { flexGrow: { xs: 1, lg: 0 } },
  mainMenuSection: {
    flexGrow: 1,
    display: { xs: "none", lg: "flex" },
  },
  profileSection: {
    flexGrow: 0,
  },
  root: {
    display: "flex",
  },
  menuItem: {
    minWidth: "0px",
    marginTop: "4px",
    marginBottom: "4px",
  },
};

export default styles;
