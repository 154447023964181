const styles = {
  root: {
    background: "#FF6131",
    //border: "solid 1px #dbaf02",
    padding: "0 4.8px",
    borderRadius: "16px",
    color: "#FFF",
    fontWeight: "600",
    textTransform: "uppercase",
    letterSpacing: "0.04rem",
    height: "16px",
    //display: "flex",
    alignItems: "center",
    //boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
  },
};

export default styles;
