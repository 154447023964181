import styles from "./styles";

export function NewTag({
  tag,
  background = "#FF6131",
  color = "#FFF",
  fontSize = "0.7rem",
}) {
  return (
    <span style={{ ...styles.root, color, background, fontSize }}>{tag}</span>
  );
}
