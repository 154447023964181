/**
 * Array with all sources
 */
export const EXTERNAL_SOURCES = {
  mercadolibre: "mercadolibre",
  amazon: "amazon",
};

export const BASIC_SOURCE_DATA = {
  mercadolibre: {
    key: "mercadolibre",
    name: "Mercado libre",
    src: "/images/ml.svg",
    w: "6vw",
    h: "4.39hv",
    hasAuthLink: true,
    hasUpdateAuthLink: true,
    authLink: "",
    updateAuthLink: "",
    hasAlias: true,
    logo: "/images/mcl-logo.svg",
    link: "https://mercadolibre.com/",
    instructionLink:
      "https://uploads-ssl.webflow.com/62bcac6104dc9d7db18c2bdb/64f5ebe3e7f402b9cbe387ae_263027123-c6d8b408-44dc-4d4c-96a9-0c3da5e8de3e.jpg",
    source_group: "marketplace",
  },
  linio: {
    key: "linio",
    name: "Linio",
    src: "/images/linio.svg",
    w: "5.5vw",
    h: "3.22hv",
    hasEmail: true,
    hasPassword: true,
    hasToken: true,
    hasAlias: true,
    logo: "/images/linio-logo.svg",
    link: "",
    source_group: "marketplace",
    instructionLink:
      "https://uploads-ssl.webflow.com/62bcac6104dc9d7db18c2bdb/64f5ebe3cb1f1c2f32530db2_263046236-84681a33-bead-4caf-a5c2-e1886a4705f7.png",
  },
  ripley: {
    key: "ripley",
    name: "Ripley",
    src: "/images/logo-ripley-gray.png",
    w: "5.9vw",
    h: "4.39hv",
    //hasEmail: true,
    //hasPassword: true,
    hasToken: true,
    hasAlias: true,
    hasShop_id: true,
    hasShop_id_fulfillment: true,
    logo: "/images/logo-ripley.webp",
    link: "https://ripley-prod.mirakl.net/",
    source_group: "marketplace",
    instructionLink:
      "https://uploads-ssl.webflow.com/62bcac6104dc9d7db18c2bdb/64f5ebe317f515e82d0bc2b7_263042315-af6f6764-b535-4bff-af09-b81c472b83f6.png",
  },
  falabella: {
    key: "falabella",
    name: "Falabella",
    src: "/images/falabella-gray.png",
    w: "5.94vw",
    h: "3.8hv",
    hasEmail: true,
    hasPassword: true,
    hasAlias: true,
    logo: "/images/falabella-logo.webp",
    link: "https://appsellerlink.falabella.com/",
    source_group: "marketplace",
    instructionLink:
      "https://uploads-ssl.webflow.com/62bcac6104dc9d7db18c2bdb/64f5ebe3f5cd55029b5071ef_263050391-828dad82-6ae7-431f-9457-8fd8614bd644.png",
  },
  newfalabella: {
    key: "newfalabella",
    name: "Falabella - Seller Center",
    src: "/images/new-falabella-gray.png",
    w: "5.94vw",
    h: "3.8hv",
    hasEmail: true,
    hasPassword: true,
    hasToken: true,
    hasAlias: true,
    logo: "/images/new-falabella-logo.webp",
    link: "https://sellercenter.falabella.com/",
    source_group: "marketplace",
    instructionLink:
      "https://uploads-ssl.webflow.com/62bcac6104dc9d7db18c2bdb/64f5ebe323d55b3b77a9a90c_263032478-5d93d500-361d-496d-93c6-8ac42906ab3b.png",
  },
  paris: {
    key: "paris",
    name: "Paris",
    src: "/images/paris-logo-gray.png",
    w: "3.19vw",
    h: "4.19hv",
    hasEmail: true,
    hasPassword: true,
    isRounded: true,
    hasAlias: true,
    logo: "/images/paris-logo.webp",
    link: "https://marketplace.paris.cl/",
    source_group: "marketplace",
    instructionLink:
      "https://uploads-ssl.webflow.com/62bcac6104dc9d7db18c2bdb/64f5ebe3522eea73c9acf802_263041390-35321e72-d444-4124-965b-f45c5fc08682.png",
  },
  magento: {
    key: "magento",
    name: "Magento",
    src: "/images/magento-logo-gray.png",
    w: "6vw",
    h: "5.39hv",
    hasUserName: true,
    hasPassword: true,
    hasUrl: true,
    hasAlias: true,
    logo: "/images/magento-logo.webp",
    link: "",
    source_group: "website",
  },
  dafiti: {
    key: "dafiti",
    name: "Dafiti",
    src: "/images/dafiti-logo-gray.png",
    w: "5.5vw",
    h: "3.8hv",
    hasEmail: true,
    hasPassword: true,
    hasToken: true,
    hasAlias: true,
    logo: "/images/dafiti-logo.webp",
    link: "",
    source_group: "marketplace",
    instructionLink:
      "https://uploads-ssl.webflow.com/62bcac6104dc9d7db18c2bdb/64f5ebe352d3de08038527c9_263048783-1ae508e7-f26b-4316-af1d-a9ddcc3bf461.png",
  },
  sodimac: {
    key: "sodimac",
    name: "Sodimac",
    src: "/images/sodimac-logo-gray.png",
    w: "4.347vw",
    h: "5.322hv",
    hasEmail: true,
    hasPassword: true,
    isRounded: true,
    hasAlias: true,
    logo: "/images/sodimac-logo.webp",
    link: "http://appmarketplace.sodimac.com/",
    source_group: "marketplace",
    instructionLink:
      "https://uploads-ssl.webflow.com/62bcac6104dc9d7db18c2bdb/64f5ebe4b2f83af46ce3d980_263050761-501a26b9-57a1-4155-b3ab-13ec269293e6.png",
  },
  vtex: {
    key: "vtex",
    name: "Vtex",
    src: "/images/vtex-logo-gray.png",
    w: "6vw",
    h: "5.322hv",
    hasToken: true,
    hasApiToken: true,
    hasVtex_account: true,
    hasAlias: true,
    logo: "/images/vtex-logo.webp",
    link: "",
    source_group: "website",
    instructionLink:
      "https://uploads-ssl.webflow.com/62bcac6104dc9d7db18c2bdb/64f5ebe6be1ffa0f36525fac_263052495-23e0e0c6-f322-4430-82b3-a8f4f358bd7c.png",
  },
  prestashop: {
    key: "prestashop",
    name: "Prestashop",
    src: "/images/prestashop-logo-gray.png",
    w: "5.29vw",
    h: "5.322hv",
    hasUrl: true,
    hasToken: true,
    hasAlias: true,
    logo: "/images/prestashop-logo.webp",
    link: "",
    source_group: "website",
    instructionLink:
      "https://uploads-ssl.webflow.com/62bcac6104dc9d7db18c2bdb/64f5ebe32638a92e749417b8_263040637-375341db-46d6-4eda-a732-fe511643eb9d.png",
  },
  shopify: {
    key: "shopify",
    name: "Shopify",
    src: "/images/shopify-logo-gray.png",
    w: "6.22vw",
    h: "5.73hv",
    hasShopifyAccount: true,
    hasShopifyApiToken: true,
    hasAlias: true,
    logo: "/images/shopify-logo.webp",
    link: "",
    source_group: "website",
    instructionLink:
      "https://cdn.prod.website-files.com/62bcac6104dc9d40a98c2c00/66bbd2c9d1247f2b0f042c5a_66bbcf16b56f5ebf008a8782_p12.png",
  },
  woocommerce: {
    key: "woocommerce",
    name: "Woocommerce",
    src: "/images/woocommerce-logo-gray.png",
    w: "3.24vw",
    h: "5.322hv",
    isRounded: true,
    hasUrl: true,
    hasConsumer_key: true,
    hasConsumer_secret: true,
    hasAlias: true,
    logo: "/images/woocommerce-logo.webp",
    link: "",
    source_group: "website",
    instructionLink:
      "https://uploads-ssl.webflow.com/62bcac6104dc9d7db18c2bdb/64f5ebe691f67b7db8d04754_263054587-ba9a89f8-1735-43cf-b9aa-25762277e748.png",
  },
  liverpool: {
    key: "liverpool",
    name: "Liverpool",
    src: "/images/liverpool-gray.png",
    w: "5.94vw",
    h: "3.8hv",
    hasAlias: true,
    hasToken: true,
    hasShop_id: true,
    hasShop_id_fulfillment: true,
    logo: "/images/liverpool-logo.webp",
    link: "https://liverpool-prod.mirakl.net/",
    source_group: "marketplace",
    instructionLink:
      "https://uploads-ssl.webflow.com/62bcac6104dc9d7db18c2bdb/64f5ebe632add1526b98795d_263055974-7886588f-fe2d-4732-8bbe-cd280ab3146e.png",
  },
  walmart: {
    key: "walmart",
    name: "Walmart",
    src: "/images/walmart.webp",
    w: "5.94vw",
    h: "3.8hv",
    hasClient_id: true,
    hasClient_secret: true,
    hasEmail: true,
    hasPassword: true,
    hasAlias: true,
    logo: "/images/walmart.webp",
    link: "",
    instructionLink: "",
    source_group: "marketplace",
  },
  amazon: {
    key: "amazon",
    name: "Amazon",
    src: "/images/amazon.webp",
    w: "5.94vw",
    h: "3.8hv",
    hasAuthLink: true,
    hasUpdateAuthLink: true,
    hasAlias: true,
    logo: "/images/amazon.webp",
    authLink: "https://sellercentral.amazon.com.mx/apps/authorize/consent",
    updateAuthLink: "",
    link: "",
    instructionLink: "",
    source_group: "marketplace",
  },
  coppel: {
    key: "coppel",
    name: "Coppel",
    src: "/images/coppel.webp",
    w: "5.94vw",
    h: "3.8hv",
    hasToken: true,
    hasAlias: true,
    hasShop_id: true,
    hasShop_id_fulfillment: true,
    logo: "/images/coppel.webp",
    link: "https://www.coppel.com/",
    instructionLink:
      "https://uploads-ssl.webflow.com/62bcac6104dc9d7db18c2bdb/64f5ebe632add1526b98795d_263055974-7886588f-fe2d-4732-8bbe-cd280ab3146e.png",
    source_group: "marketplace",
  },
};
