import { Divider, MenuItem } from "@mui/material";

import PremiumIcon from "components/Icons/PremiumIcon";
import { NewTag } from "components/NewTag/NewTag";

import styles from "./styles";

export function SubMenuItem({ item, index, menu, handleOnSubItemClick }) {
  return (
    <div
      key={item.id}
      onClick={(e) => handleOnSubItemClick(e, item.id, item.route, item.plan)}
    >
      <MenuItem
        key={item.id}
        sx={item.active ? styles.menuItemActive : {}}
        style={{ justifyContent: "space-between" }}
      >
        <div style={styles.item} data-cy={item["data-cy"]}>
          {item.name}
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            columnGap: "0.5em",
          }}
        >
          {item.plan !== "free" && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <PremiumIcon />
            </div>
          )}
          {item?.tag && <NewTag tag={item.tag} />}
        </div>
      </MenuItem>
      <Divider
        variant="middle"
        sx={{
          display: index < menu.subItems.length - 1 ? "block" : "none",
        }}
      />
    </div>
  );
}
