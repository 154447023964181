import moment from "moment";
moment.locale("es");
export const FORMAT = "YYYY-MM-DD";

/**
 *
 * @param {date} date
 * @returns
 */

export const reformateDateLegacy = (date, format) => {
  const day = moment(date, format).format("DD");
  const month = moment(date, format).format("MMM");
  const year = moment(date, format).format("YYYY");
  return `${day} ${month} ${year}`;
};

export const reformateDate = (date) => {
  const opciones = {
    day: "2-digit",
    month: "short",
    year: "numeric",
  };
  const fecha = new Date(date + `T23:59:00`);
  const dia = fecha.toLocaleDateString("es-ES", opciones);
  return dia;
};

// Función para obtener la fecha en formato "YYYY-MM-DD"
export const parseFechaYYYMMDD = (fecha) => {
  // Formatear la fecha
  const año = fecha.getFullYear();
  const mes = fecha.getMonth() + 1; // Los meses en JavaScript comienzan en 0
  const día = fecha.getDate();

  // Añadir ceros a la izquierda si es necesario
  const añoStr = año.toString();
  const mesStr = mes.toString().padStart(2, "0");
  const díaStr = día.toString().padStart(2, "0");

  // Devolver la fecha en formato "YYYY-MM-DD"
  return `${añoStr}-${mesStr}-${díaStr}`;
};

// Función para obtener el día de inicio de la semana
const getStartOfWeek = (date) => {
  const day = date.getDay();
  const diff = day ? day - 1 : 6; // Si el día es domingo, la diferencia es 6
  const startOfWeek = new Date(date.getTime() - diff * 24 * 60 * 60 * 1000);
  return parseFechaYYYMMDD(startOfWeek);
};

// Función para obtener el último día de la semana
const getEndOfWeek = (date) => {
  const day = date.getDay();
  const diff = day ? 7 - day : 1; // Si el día es domingo, la diferencia es 1
  const endOfWeek = new Date(date.getTime() + diff * 24 * 60 * 60 * 1000 - 1);
  return parseFechaYYYMMDD(endOfWeek);
};

// Creando un objeto que representa el dia actual
const currentDate = new Date();

// Número de milisegundos en un dia
const millisecondsInDay = 1000 * 60 * 60 * 24;

// Restando 1 día en milisegundo al dia actual
const yesterday = parseFechaYYYMMDD(
  new Date(currentDate.getTime() - 1 * millisecondsInDay)
);
// Subtract 7 days from the current date in milliseconds
const lasWeek = new Date(currentDate.getTime() - 7 * millisecondsInDay);

export const dateRanges = {
  Today: {
    start: moment().format(FORMAT),
    end: moment().format(FORMAT),
    plan: "free",
  },
  Yesterday: {
    start: yesterday,
    end: yesterday,
    plan: "free",
  },
  "Last week": {
    start: getStartOfWeek(lasWeek),
    end: getEndOfWeek(lasWeek),
    plan: "free",
  },
  "This week": {
    start: moment().startOf("isoWeek").format(FORMAT),
    end: moment().format(FORMAT),
    //end: moment().endOf("isoWeek").format(FORMAT),
    plan: "free",
  },
  "Last month": {
    start: moment().subtract(1, "months").startOf("month").format(FORMAT),
    end: moment().subtract(1, "months").endOf("month").format(FORMAT),
    plan: "free",
  },
  "This month": {
    start: moment().startOf("month").format(FORMAT),
    //end: moment().endOf("month").format(FORMAT),
    end: moment().format(FORMAT),
    plan: "free",
  },
  "Last 7 days": {
    start: moment().subtract(6, "days").format(FORMAT),
    end: moment().format(FORMAT),
    plan: "free",
  },
  "Last 30 days": {
    start: moment().subtract(29, "days").format(FORMAT),
    end: moment().format(FORMAT),
    plan: "free",
  },
  "Last 60 days": {
    start: moment().subtract(59, "days").format(FORMAT),
    end: moment().format(FORMAT),
    plan: "starter",
  },
};

const ranges = [
  {
    key: "yesterday",
    label: "Ayer",
    value: `${reformateDate(dateRanges["Yesterday"].start)}`,
    value1: "Yesterday",
    range: `${dateRanges["Yesterday"]}`,
    plan: "free",
    "data-cy": "Yesterday",
  },
  {
    key: "today",
    label: "Hoy",
    value: `${reformateDate(dateRanges["Today"].start)}`,
    value1: "Today",
    range: `${dateRanges["Today"]}`,
    plan: "free",
    "data-cy": "Today",
  },
  {
    key: "last_week",
    label: "Semana Anterior",
    value: `${reformateDate(
      dateRanges["Last week"].start,
      FORMAT
    )} - ${reformateDate(dateRanges["Last week"].end)}`,
    value1: "Last week",
    range: `${dateRanges["Last week"]}`,
    plan: "free",
    "data-cy": "Last week",
  },
  {
    key: "this_week",
    label: "Esta Semana",
    value: `${reformateDate(
      dateRanges["This week"].start,
      FORMAT
    )} - ${reformateDate(dateRanges["This week"].end)}`,
    value1: "This week",
    range: `${dateRanges["This week"]}`,
    plan: "free",
    "data-cy": "This week",
  },
  {
    key: "last_month",
    label: "Mes Anterior",
    value: `${reformateDate(
      dateRanges["Last month"].start,
      FORMAT
    )} - ${reformateDate(dateRanges["Last month"].end)}`,
    value1: "Last month",
    range: `${dateRanges["Last month"]}`,
    plan: "free",
    "data-cy": "Last month",
  },
  {
    key: "this_month",
    label: "Este Mes",
    value: `${reformateDate(
      dateRanges["This month"].start,
      FORMAT
    )} - ${reformateDate(dateRanges["This month"].end)}`,
    value1: "This month",
    range: `${dateRanges["This month"]}`,
    plan: "free",
    "data-cy": "This month",
  },
  {
    key: "last_7_days",
    label: "Últimos 7 días",
    value: `${reformateDate(dateRanges["Last 7 days"].start)} - ${reformateDate(
      dateRanges["Last 7 days"].end
    )}`,
    value1: "Last 7 days",
    range: `${dateRanges["Last 7 days"]}`,
    plan: "free",
    "data-cy": "Last 7 days",
  },
  {
    key: "last_30_days",
    label: "Últimos 30 días",
    value: `${reformateDate(
      dateRanges["Last 30 days"].start
    )} - ${reformateDate(dateRanges["Last 30 days"].end)}`,
    value1: "Last 30 days",
    range: `${dateRanges["Last 30 days"]}`,
    plan: "free",
    "data-cy": "Last 30 days",
  },
  {
    key: "last_60_days",
    label: "Últimos 60 días",
    value: `${reformateDate(
      dateRanges["Last 60 days"].start
    )} - ${reformateDate(dateRanges["Last 60 days"].end)}`,
    value1: "Last 60 days",
    range: `${dateRanges["Last 60 days"]}`,
    plan: "starter",
    "data-cy": "Last 60 days",
  },
  {
    key: "custom",
    label: "Personalizado",
    isCustom: true,
    value: `custom`,
    value1: `custom`,
    range: "custom",
    plan: "free",
    "data-cy": "custom",
  },
];

export const BENCHMARK_DATE_RANGES = [
  {
    key: "custom",
    label: "Personalizado",
    isCustom: true,
    value: `custom`,
    value1: `custom`,
    range: "custom",
    plan: "free",
    "data-cy": "custom",
  },
];

export default ranges;
