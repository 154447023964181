/* eslint-disable */
import { lazy } from "react";

import { Redirect } from "react-router-dom";

const errorLoading = (err) => {
  console.error("Dynamic page loading failed", err); // eslint-disable-line no-console
};

export default function createRoutes(store) {
  return [
    {
      path: "/",
      name: "root",
      component: lazy(() =>
        Promise.all([import("./containers/Login/Auth0Login")])
          .then(([component]) => {
            return component;
          })
          .catch(errorLoading)
      ),
    },
    {
      path: "/login",
      name: "login",
      component: lazy(() =>
        Promise.all([import("./containers/Login/Auth0Login")])
          .then(([component]) => {
            return component;
          })
          .catch(errorLoading)
      ),
    },
    {
      path: "/signup",
      name: "Signup",
      component: lazy(() =>
        Promise.all([import("./containers/Login/Auth0Signup")])
          .then(([component]) => {
            return component;
          })
          .catch(errorLoading)
      ),
    },
    {
      path: `/onBoarding/stage/*`,
      name: "onBoarding",
      component: lazy(() =>
        Promise.all([import("./containers/OnBoarding/OnBoarding")])
          .then(([component]) => {
            return component;
          })
          .catch(errorLoading)
      ),
    },
    {
      path: `/home`,
      name: "home",
      component: lazy(() =>
        Promise.all([import("./containers/Home/NewHome")])
          .then(([component]) => {
            return component;
          })
          .catch(errorLoading)
      ),
    },
    {
      path: `/reports`,
      name: "reports",
      component: lazy(() =>
        Promise.all([import("./containers/Home/Home")])
          .then(([component]) => {
            return component;
          })
          .catch(errorLoading)
      ),
    },
    {
      path: `/benchmark`,
      name: "benchmark",
      component: lazy(() =>
        Promise.all([import("./containers/Benchmark/Benchmark")])
          .then(([component]) => {
            return component;
          })
          .catch(errorLoading)
      ),
    },
    /*  {
      path: `/publishing/dashboard/:dashboardId`,
      name: "dashboard",
      component: lazy(() =>
        Promise.all([import("./containers/Dashboards/Dashboards")])
          .then(([component]) => {
            return component;
          })
          .catch(errorLoading)
      ),
    }, */
    /*{
      path: `/keywords/dashboard/:dashboardId`,
      name: "keywords",
      component: lazy(() =>
        Promise.all([import("./containers/Dashboards/Dashboards")])
          .then(([component]) => {
            return component;
          })
          .catch(errorLoading)
      ),
    },*/
    {
      path: `/profitability/dashboard/:dashboardId`,
      name: "profitability",
      component: lazy(() =>
        Promise.all([import("./containers/Dashboards/Dashboards")])
          .then(([component]) => {
            return component;
          })
          .catch(errorLoading)
      ),
    },
    {
      path: `/sales/dashboard/:dashboardId`,
      name: "profitability",
      component: lazy(() =>
        Promise.all([import("./containers/Dashboards/Dashboards")])
          .then(([component]) => {
            return component;
          })
          .catch(errorLoading)
      ),
    },
    {
      path: "/reports/:reportType/:reportId",
      name: "Resume",
      component: lazy(() =>
        Promise.all([import("./containers/Reports/Reports")])
          .then(([component]) => {
            return component;
          })
          .catch(errorLoading)
      ),
    },
    {
      path: "/marketplaces/accounts",
      name: "integration",
      component: lazy(() =>
        Promise.all([import("./containers/Marketplaces/Marketplaces")])
          .then(([component]) => {
            return component;
          })
          .catch(errorLoading)
      ),
    },
    {
      path: "/marketplaces/accounts/:sourceKey/new_account",
      name: "integration",
      component: lazy(() =>
        Promise.all([
          import("./components/MarketplaceLayout/MarketplaceLayout"),
        ])
          .then(([component]) => {
            return component;
          })
          .catch(errorLoading)
      ),
    },
    {
      path: "/marketplaces/accounts/:sourceKey/update_account/:identifier",
      name: "integration",
      component: lazy(() =>
        Promise.all([
          import("./components/MarketplaceLayout/MarketplaceLayout"),
        ])
          .then(([component]) => {
            return component;
          })
          .catch(errorLoading)
      ),
    },
    {
      path: "/alerts",
      name: "alerts",
      component: lazy(() =>
        Promise.all([import("./containers/Alerts/Alerts")])
          .then(([component]) => {
            return component;
          })
          .catch(errorLoading)
      ),
    },
    /*  {
      path: "/publishing/admin",
      name: "publishing",
      component: lazy(() =>
        Promise.all([import("./containers/Publishing/PublishingManagement")])
          .then(([component]) => {
            return component;
          })
          .catch(errorLoading)
      ),
    }, */
    /*{
      path: "/keywords/admin",
      name: "keywords",
      component: lazy(() =>
        Promise.all([import("./containers/Publishing/KeywordsManagement")])
          .then(([component]) => {
            return component;
          })
          .catch(errorLoading)
      ),
    },*/
    /*  {
      path: "/publishing/:publisingId",
      name: "publishing details",
      component: lazy(() =>
        Promise.all([
          import(
            "./containers/Dashboards/DashboardsViews/Publishing/PublishingDetails/PublishingDetails"
          ),
        ])
          .then(([component]) => {
            return component;
          })
          .catch(errorLoading)
      ),
    }, */
    {
      path: "/publications*",
      name: "publications monitor",
      component: lazy(() =>
        Promise.all([
          import("./containers/PublicationsMonitor/PublicationsMonitor"),
        ])
          .then(([component]) => {
            return component;
          })
          .catch(errorLoading)
      ),
    },
    {
      path: "/orders*",
      name: "orders",
      component: lazy(() =>
        Promise.all([import("./containers/Orders/Orders")])
          .then(([component]) => {
            return component;
          })
          .catch(errorLoading)
      ),
    },
    /*{
      path: "/keywords/:sourceType/:keywordId",
      name: "keyword details",
      component: lazy(() =>
        Promise.all([
          import(
            "./containers/Dashboards/DashboardsViews/Competition/KeywordDetails/KeywordDetails"
          ),
        ])
          .then(([component]) => {
            return component;
          })
          .catch(errorLoading)
      ),
    },*/
    /** end cube reports **/
    {
      path: "/livez",
      name: "root",
      component: lazy(() =>
        Promise.all([import("./containers/Login/Auth0Login")])
          .then(([component]) => {
            return component;
          })
          .catch(errorLoading)
      ),
    },
    {
      path: "*",
      name: "notfound",
      component: lazy(() => {
        return import("./containers/NotFoundPage/NotFoundPage");
      }),
    },
  ];
}
