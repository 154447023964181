import React, { useEffect, useRef, useState } from "react";

import { ThemeProvider } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";

import Content from "components/Content/Content";
import { uitheme } from "./styles";

import { LoadingAlert } from "./LoadingAlert";
import { WivoAppBar } from "components/AppBar/WivoAppBar";
import {
  PLAN_MANAGEMENT,
  SEGMENT_EVENT,
  TENANT_STATUS,
} from "utils/app/appConstants";
import { PremiumModal } from "components/Menu/PremiumModal";
import { updateAppState } from "redux/App/actions";
import {
  activateTrialRequest,
  changeTenantProperty,
  updateCliente,
} from "services/alpaca/request";
import { ReloadAlert } from "./ReloadAlert";
import {
  planUpgradeRequest,
  trialRequest,
} from "services/almiqui/publishing/request";
import { useSnackbar } from "notistack";
import moment from "moment";
import { registerEvent, registerSentryError } from "utils/utils";
import { sendSlackNotification } from "services/almiqui/slack/request";
import { sendEmail } from "services/almiqui/emails/request";
import { updateUserIdentity } from "redux/UserIdentity/actions";
import { WivoAlert } from "./WivoAlert";
import Modal from "components/Modal/Modal";
import { BenchmarkContent } from "./BenchmarkContent";
import Toast from "components/Toast";
import { NameUpdateModal } from "./NameUpdateModal";
import { registerUserDataAut0 } from "services/alpaca/request";

const { ACTIVATE_TRIAL, UPGRADE_PLAN } = PLAN_MANAGEMENT;
const { TRIAL_ACTIVATED, TRIAL_AUTO_ACTIVATED } = SEGMENT_EVENT;
const TRIAL_ACTIVATED_MESSAGE = {
  type: "success",
  title: "Período de trial activado",
  text: "A partir de ahora podrás disfrutar de las funcionalidades del plan PRO, por un periodo de 7 días.",
};
const TRIAL_ACTIVATED_ERROR = {
  type: "error",
  title: "Error",
  text: "La solicitud de activación de trial ha fallado, intente nuevamente más tarde o notifique al equipo de Wivo.",
};
const AppLayout = (props) => {
  const path = props.location.pathname;
  const identity = useSelector((state) => state.get("userIdentity"));
  const integrationsStatus = useSelector((state) =>
    state.get("integrationsStatus")
  );

  const [reload, setReload] = useState(false);
  const [openReload, setOpenReload] = useState(false);
  const app = useSelector((state) => state.get("app"));
  const [isBannerVisible, setBannerVisible] = useState(false);
  const hasTrial = useRef();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const route = useSelector((state) => state.get("route"));
  const [benchmarkModalOpen, setOpenBenchmarkModal] = useState(false);
  const routeObject = route.get("locationBeforeTransitions");
  const [toast, setToast] = useState({
    show: false,
    type: "success",
    title: "",
    text: "",
  });
  const [openEditName, setOpenEditName] = useState(false);
  const [userNameUpdated, setUserNameUpdated] = useState("");

  const { pathname } = routeObject || { pathname: "" };

  const {
    newVersionAvailable,
    planManagement,
    gloablOpenReload,
    initialSeconds = 5,
  } = app;
  const { accountData, userData, isDemo } = identity;
  const tenant_status = accountData?.properties?.tenant_status;
  const { email, name, user_metadata, user_id } = userData;
  const { tenant, client, properties } = accountData;
  const { action, open, title, subtitle, features, cta } = planManagement;
  const { trial } = properties || {};
  const { demoEnabled } = isDemo;
  const { integrationsCount, hasAnySourcesOK } = integrationsStatus;
  const rootClass =
    props.loaded === false ? "app-wrapper-loading" : "app-wrapper";
  const isReportList = path.includes("/reports");
  const { milestones } = user_metadata || {};
  const hasBenchmarkMilestone = user_id
    ? milestones?.includes("benchmark_invited_user")
    : "true";

  useEffect(() => {
    let timer = null;
    if (
      email &&
      name &&
      email === name &&
      tenant_status === TENANT_STATUS.ENABLED
    ) {
      timer = setTimeout(() => setOpenEditName(true), 7000);
    }
    if (timer)
      return () => {
        clearTimeout(timer);
      };
  }, [email, name, tenant_status]);

  const closePlanManagementDialog = () => {
    dispatch(
      updateAppState({ planManagement: { ...planManagement, open: false } })
    );
  };

  useEffect(() => {
    if (trial) hasTrial.current = trial;
  }, [trial]);

  const activeTrial = (user, auto) => {
    const trial = { tenant, type: "pro", duration: 7 };
    const event = auto ? TRIAL_AUTO_ACTIVATED : TRIAL_ACTIVATED;
    activateTrialRequest(trial)
      .then((response) => {
        const { status, err } = response;
        if (err) throw new Error("Trial request error");
        if (status >= 200 && status <= 300) {
          trialRequest(user).then((response) => {});
          setReload(true);
          activateToast(TRIAL_ACTIVATED_MESSAGE);
          registerEvent(event, {
            name,
            email,
            company: client,
            groupId: tenant,
            tenant_status,
          });
        }
      })
      .catch((err) => activateToast(TRIAL_ACTIVATED_ERROR));
  };

  const reActiveTrial = (user, auto) => {
    const event = auto ? TRIAL_AUTO_ACTIVATED : TRIAL_ACTIVATED;
    const newProperties = {
      ...properties,
      trial: [
        { ...trial[0], duration: 7, start_date: moment().format("YYYY-MM-DD") },
      ],
    };
    return updateCliente({ properties: newProperties }, tenant)
      .then((resp) => {
        const { status, err } = resp;
        if (err) throw new Error();
        if (status >= 200 && status <= 300) {
          trialRequest(user).then((response) => {});
          setReload(true);
          activateToast(TRIAL_ACTIVATED_MESSAGE);
          registerEvent(event, {
            name,
            email,
            company: client,
            groupId: tenant,
            tenant_status,
          });
        }
      })
      .catch((err) => activateToast(TRIAL_ACTIVATED_ERROR));
  };

  const upgradePlan = (user) => {
    planUpgradeRequest(user)
      .then((response) => {
        const { status, err } = response;
        if (err) throw new Error("Plan uprade request error");
        if (status >= 200 && status <= 200) {
          activateToast(TRIAL_ACTIVATED_MESSAGE);
        }
      })
      .catch((err) => activateToast(TRIAL_ACTIVATED_ERROR));
  };

  const handleCallToAction = (action) => {
    closePlanManagementDialog();
    const user = { username: name, tenantname: client, tenant, email };
    if (action === ACTIVATE_TRIAL) {
      if (trial) reActiveTrial(user, false);
      else activeTrial(user, false);
    }
    if (action === UPGRADE_PLAN) upgradePlan(user);
  };

  const activateToast = (toastData) => {
    return enqueueSnackbar("", {
      variant: "snackbar",
      ...toastData,
    });
  };

  useEffect(() => {
    if (
      properties &&
      !properties?.trial &&
      properties?.plan?.toLowerCase() !== "pro" &&
      integrationsCount === 1 &&
      hasAnySourcesOK &&
      (tenant_status === TENANT_STATUS.ENABLED ||
        tenant_status === TENANT_STATUS.LOADING)
    ) {
      const user = { username: name, tenantname: client, tenant, email };
      if (properties?.trial) {
        reActiveTrial(user, true);
      } else {
        activeTrial(user, true);
      }
    }
  }, [properties, tenant_status, integrationsCount, hasAnySourcesOK]);

  useEffect(() => {
    if (integrationsCount === 1 && hasAnySourcesOK) {
      if (tenant_status === TENANT_STATUS.ONBOARDING) {
        changeTenantProperty({
          tenant,
          key: "tenant_status",
          value: TENANT_STATUS.ENABLED,
        })
          .then(async (resp) => {
            const { error, status } = resp;
            if (error || status === 500 || status === 400) {
              throw new Error("Ha ocurrido un error al activar la cuenta");
            }
            if (status >= 200 && status <= 300) {
              sendSlackNotification({
                message: `El tenant "${client}" (${tenant}) ya está habilitado`,
              });
              //Envío de email al usuario para notificar que la cuenta ya está habilitada
              sendEmail({ email, username: name, tenantname: client });
              registerEvent("onboarding_change_tenant_status", {
                result: "Success",
                status: "ENABLED",
                name: name,
                email: email,
                company: client,
                groupId: tenant,
              });
              dispatch(
                updateUserIdentity({
                  accountData: {
                    ...identity.accountData,
                    properties: {
                      ...identity.accountData.properties,
                      tenant_status: "ENABLED",
                    },
                  },
                })
              );
            }
          })
          .catch((err) => {
            registerEvent("onboarding_change_tenant_status", {
              result: "Error",
              status: "ONBOARDING",
              name: name,
              email: email,
              company: client,
              groupId: tenant,
              error: err?.message,
            });
          });
      }
    }
  }, [integrationsCount, hasAnySourcesOK]);

  const trillerReload = () => {
    if (reload) {
      setOpenReload(true);
      setReload(false);
    }
  };

  useEffect(() => {
    if (reload) {
      trillerReload();
    }
  }, [reload]);

  const handleOnActionClick = () => {
    setOpenBenchmarkModal(true);
  };
  const closeBenchmarkModal = (event, reason) => {
    setOpenBenchmarkModal(false);
  };

  const closeToast = () => {
    setToast({ show: false, type: "success", title: "", text: "" });
  };

  const updateUserName = () => {
    registerUserDataAut0({
      user_id,
      user_data: { name: userNameUpdated },
    })
      .then((resp) => {
        const { err, data } = resp;
        if (err) throw new Error();
        if (data) {
          dispatch(
            updateUserIdentity({
              userData: {
                ...identity.userData,
                name: userNameUpdated,
              },
            })
          );
          const type = "success";
          const title = "Nombre actualizado";
          const text = "Tu nombre ha sido actualizado satisfactoriamente.";
          const toastData = { type, title, text };
          enqueueSnackbar("", {
            variant: "snackbar",
            ...toastData,
          });
        }
        return resp;
      })
      .catch((err) =>
        registerSentryError(
          err?.message || "Error al actualizar el nombre del usuario en Auth0"
        )
      );
  };

  return (
    <ThemeProvider theme={uitheme}>
      <div>
        <div className={rootClass}>
          <WivoAppBar {...props} />
          <LoadingAlert activeBanner={(visible) => setBannerVisible(visible)} />
          {pathname === "/benchmark" &&
            !hasBenchmarkMilestone &&
            !demoEnabled && (
              <WivoAlert
                open={true}
                message={"¡Ayúdanos a mejorar tus benchmark!"}
                actionLabel={"Ver más"}
                onActionClick={handleOnActionClick}
                actionOnTitle={true}
              />
            )}
          {(openReload || gloablOpenReload) && (
            <ReloadAlert initialSeconds={initialSeconds} />
          )}
          <Content
            isBannerVisible={isBannerVisible}
            isReportList={isReportList}
            style={{
              backgroundColor: path.includes("/onBoarding/stage")
                ? "#FFF"
                : "var(--blue-gray-1)",
              marginTop: 0,
            }}
          >
            <div
              style={{
                paddingTop: 0,
                backgroundColor: path.includes("/onBoarding/stage")
                  ? "#FFF"
                  : "var(--blue-gray-1)",
                height: newVersionAvailable ? "95%" : "100%",
              }}
            >
              {React.Children.toArray(props.children).map((child) =>
                React.cloneElement(child, { ...props })
              )}
            </div>
          </Content>
          <PremiumModal
            action={action}
            open={open}
            close={closePlanManagementDialog}
            title={title}
            subtitle={subtitle}
            features={features}
            cta={cta}
            onCTA={handleCallToAction}
          />
          <Modal
            fullScreen
            fullWidth
            open={benchmarkModalOpen}
            handleClose={closeBenchmarkModal}
            title={"¡Ayúdanos a mejorar tus benchmark!"}
            content={<BenchmarkContent onInvite={closeBenchmarkModal} />}
            toClose
            disabledAction
          />
          <Modal
            fullScreen
            fullWidth
            open={openEditName}
            handleClose={() => setOpenEditName(false)}
            title={"Actualiza tu nombre"}
            content={<NameUpdateModal onChange={setUserNameUpdated} />}
            toClose
            type="confirm"
            label="Actualizar"
            okAction={updateUserName}
          />
          <Toast show={toast.show} close={closeToast} data={toast} />
        </div>
      </div>
    </ThemeProvider>
  );
};

export default AppLayout;
