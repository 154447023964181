import { useEffect } from "react";

import { Alert, Button, Fade } from "@mui/material";

import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: "#3770EF",
    "&:hover": {
      backgroundColor: "#3770efbd",
    },

    letterSpacing: "0px",
    height: "26px",
    //borderRadius: "999px",
    //border: "1px solid rgba(102, 179, 255, 0.2)",
    fontSize: "0.71rem",
    color: "#fff",
    //textTransform: "initial",
    width: "max-content",
  },
}));

export function WivoAlert({
  open,
  message,
  actionLabel,
  onActionClick,
  severity,
  actionOnTitle,
}) {
  const classes = useStyles();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    onActionClick();
  };

  useEffect(() => {}, []);

  return (
    <Fade in={true} style={{ transitionDelay: true ? "500ms" : "0ms" }}>
      <Alert
        action={
          <Button className={classes.button} onClick={handleClose}>
            {actionLabel}
          </Button>
        }
        severity={severity || "info"}
        sx={{
          width: "100%",
          pl: 5,
          pr: 7,
          "& .MuiAlert-action": actionOnTitle
            ? {
                marginLeft: "16px",
                padding: "unset",
                alignItems: "center",
              }
            : null,
        }}
      >
        <span className="body1">{message}</span>
      </Alert>
    </Fade>
  );
}
