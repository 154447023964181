import { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { TextField } from "@mui/material";

export function NameUpdateModal({ onChange }) {
  const [name, setName] = useState("");
  const identity = useSelector((state) => state.get("userIdentity"));

  const identityName = identity?.userData?.name;

  useEffect(() => {
    if (identityName) {
      setName(identityName);
    }
  }, [identityName]);

  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <p
        style={{
          fontSize: "1rem",
          lineHeight: "1.5",
          textWrapStyle: "pretty",
          display: "inline-block",
          margin: "0",
        }}
      >
        Queremos mejorar tu experiencia de trabajo en equipo, te pedimos que
        actualices tu nombre completo.
      </p>
      <TextField
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            return;
          }
        }}
        autoFocus
        value={name}
        placeholder={"Escribe tu nombre..."}
        helperText={
          <span style={{ marginTop: "0.5em" }} className="body1">
            Con este nombre serás visible por el resto de tu equipo
          </span>
        }
        sx={{
          //maxWidth: "25em",
          "& .MuiFormHelperText-root": {
            fontSize: "0.9rem",
          },
        }}
        fullWidth
        margin="normal"
        onChange={(e) => {
          setName(e.target.value);
          onChange(e.target.value);
        }}
        inputProps={{
          style: {
            color: "#3770EF",
            fontSize: "1.6em",
          },
        }}
        variant="standard"
      />
    </div>
  );
}
